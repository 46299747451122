import React, { useEffect } from "react"
import { graphql } from "gatsby"
import * as styles from "./__styles__/generic.module.scss"
import { connect } from "react-redux"
import { updatePageURI } from "./../../redux/actions"

import Layout from "../layouts/base"
import SEO from "../atoms/seo"
import Hero from "../molecules/hero"
import Cards from "../organisms/cards"
import FlexibleContent from "../organisms/flexible-content"

// Redux
const mapDispatchToProps = {
  updatePageURIAction: updatePageURI,
}

function Page({ updatePageURIAction, data }) {
  const page = data.wpPage
  const cards = page.cards.cardsElements
  const flexibleContent = page.flexContent && page.flexContent.flexItems

  useEffect(() => {
    updatePageURIAction(page.uri)
  })

  return (
    <Layout>
      <SEO
        description={page.seo.metaDesc}
        robots={page.seo.metaRobotsNoindex}
        slug={page.uri}
        title={page.seo.title}
      />
      <Hero
        title={page.hero.title || page.title}
        lead={page.hero.lead}
        pageId={page.id}
      />
      {cards && (
        <div className={styles.cards}>
          <Cards cards={cards} />
        </div>
      )}
      {flexibleContent && (
        <div className={styles.flexibleContentContainer}>
          <FlexibleContent elements={flexibleContent} />
        </div>
      )}
    </Layout>
  )
}

export default connect(null, mapDispatchToProps)(Page)

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      uri
      seo {
        title
        metaDesc
        metaRobotsNoindex
      }
      cards {
        cardsElements {
          title
          copy
          link {
            url
            title
            target
          }
        }
      }
      hero {
        title
        lead
      }
      flexContent {
        flexItems {
          __typename
          ... on WpPage_Flexcontent_FlexItems_Copy {
            copy
          }
          ... on WpPage_Flexcontent_FlexItems_Image {
            image {
              id
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 547, height: 312, layout: CONSTRAINED)
                }
              }
            }
          }
          ... on WpPage_Flexcontent_FlexItems_Video {
            video
          }
          ... on WpPage_Flexcontent_FlexItems_Downloads {
            downloads {
              name
              file {
                localFile {
                  extension
                  publicURL
                  url
                  size
                }
              }
            }
          }
          ... on WpPage_Flexcontent_FlexItems_Accordion {
            accordion {
              description
              title
            }
          }
        }
      }
    }
  }
`
